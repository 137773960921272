<template>
	<v-card v-if="!isLoading" class="pa-6">
		<v-row>
			<v-col cols="12" class="pa-0">
				<h1 class="text-h6 mb-4">Services</h1>
				<search
					:initialData="services"
					:url="searchUrl"
					@search-result="updateService($event)"
				></search>
			</v-col>

			<v-col cols="12" class="pa-0">
				<h1 class="text-h6 mt-4">Memberships</h1>
				<v-text-field
					v-model="membershipName"
					@keyup.enter="updateMembership()"
					placeholder="Enter your Memberships here"
				></v-text-field>
				<div v-if="showChip">
					<v-chip
						v-for="membership in memberships"
						:key="membership.uuid"
						class="mr-2 chip"
						close
						@click:close="removeMembership(membership.uuid)"
					>
						{{ membership.name }}
					</v-chip>
				</div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import search from '@/components/Search'
export default {
	components: {
		search,
	},
	data() {
		return {
			membershipName: '',
			showChip: false,
			searchUrl: '',
		}
	},
	created() {
		if (this.$route.path.includes('/edit')) {
			this.showChip = true
		}
		this.getSearchUrl()
	},
	computed: {
		isLoading: {
			get() {
				return this.$store.state.professional.isLoading
			},
		},
		services: {
			get() {
				return this.$store.state.professional.services
			},
		},
		memberships: {
			get() {
				return this.$store.state.professional.memberships
			},
		},
	},
	methods: {
		getSearchUrl() {
			const projectId = this.$store.state.app.projectId
			this.searchUrl = `/${projectId}/services/search`
		},
		updateMembership() {
			if (this.membershipName.trim().length > 0) {
				let payload = {
					name: this.membershipName,
					uuid: uuidv4(),
				}
				this.$store.commit('professional/updateMemberships', payload)
				this.showChip = true
				this.membershipName = ''
			}
		},
		removeMembership(id) {
			this.$store.commit('professional/deleteMembership', id)
		},
		updateService(arr) {
			this.$store.commit('professional/updateServices', arr)
		},
	},
}
</script>

<style scoped>
.chip {
	background-color: #f3f3f3 !important;
	border: 1px solid #ccc;
}
.chip > i {
	cursor: pointer;
	float: right;
	font-size: 16px;
	line-height: 32px;
	padding-left: 8px;
}
</style>
