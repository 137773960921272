<template>
	<v-dialog v-model="openDialog" persistent max-width="800px">
		<v-card class="pa-6 mb-0">
			<div class="d-flex justify-space-between align-start">
				<h1 class="text-h6 mb-5">Edit Award And Recognition</h1>
				<span
					class="mdi mdi-close close"
					@click="resetValidation()"
				></span>
			</div>

			<v-form @submit.prevent="validateAwards" ref="form">
				<v-row>
					<v-col cols="12" class="pa-0">
						<FileUploader
							:initFiles="banner ? [banner] : []"
							@errorOccured="dropzoneError"
							:purpose="`award logo`"
							:maxFiles="1"
							title="Drag/Drop or click here to upload image"
							@changed="fileUploaderChanged"
						/>
					</v-col>

					<v-col cols="12" class="py-0 pr-2">
						<p>Image Alt Text:</p>
						<v-text-field
							class="pa-0"
							v-model="altText"
							placeholder="Enter Alt Text"
							:error-messages="errors ? errors.altText : ''"
						></v-text-field>
					</v-col>

					<v-col cols="9" class="py-0 pr-1">
						<p>Given By:</p>
						<v-text-field
							class="pa-0"
							v-model="givenBy"
							placeholder="India Today Excellence Healthcare Awards"
							@input="$v.givenBy.$touch()"
							@blur="$v.givenBy.$touch()"
							:error-messages="givenByErrors()"
						></v-text-field>
					</v-col>
					<v-col cols="3" class="ma-0 pa-0 pr-2">
						<p>Year :</p>
						<v-select
							class="pa-0"
							:items="yearsArray"
							placeholder="Select Year"
							v-model="year"
							@focus="dropdown()"
							:error-messages="errors ? errors.year : ''"
						></v-select>
					</v-col>

					<v-col cols="12" class="px-0 pb-0 pl-2">
						<p>Award/Recognition Name* :</p>
						<v-text-field
							class="pa-0"
							v-model="name"
							placeholder="3rd Best in Hair Transplant in India"
							@input="$v.name.$touch()"
							@blur="$v.name.$touch()"
							:error-messages="nameErrors()"
						></v-text-field>
					</v-col>
				</v-row>

				<v-card-actions class="pt-6 card-actions">
					<v-btn
						v-if="isEdit"
						color="rgba(0,0,0,.6)"
						outlined
						class="delete-btn pa-3"
						@click="deleteAward(uuid)"
					>
						Delete
					</v-btn>

					<v-spacer></v-spacer>

					<v-btn
						color="var(--blue)"
						depressed
						type="submit"
						class="save-btn pa-3"
					>
						Done
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import FileUploader from '@/components/FileUploader'
import { minLength, required } from 'vuelidate/lib/validators'
import _ from 'lodash'

export default {
	components: {
		FileUploader,
	},
	props: {
		dialog: {
			type: Boolean,
		},
		initialData: {
			type: Object,
		},
		isEdit: {
			type: Boolean,
		},
	},
	data() {
		return {
			openDialog: false,
			yearsArray: [],
			year: null,
			name: '',
			givenBy: '',
			altText: '',
			uuid: '',
			banner: null,
			bannerId: null,
		}
	},
	validations: {
		name: {
			required,
		},
		givenBy: {
			minLength: minLength(2),
		},
	},
	watch: {
		year() {
			if (this.errors) {
				this.errors.year = ''
			}
		},
		altText() {
			this.altTextChanged()
		},
		name() {
			if (this.errors) {
				this.errors.name = ''
			}
		},
		givenBy() {
			if (this.errors) {
				this.errors.givenBy = ''
			}
		},
	},
	created() {
		this.openDialog = this.dialog
		if (this.isEdit) {
			this.year = this.initialData.year
			this.altText =
				this.initialData.banner && this.initialData.banner.altText
					? this.initialData.banner.altText
					: null
			this.givenBy = this.initialData.givenBy
			this.name = this.initialData.name
			this.uuid = this.initialData.uuid
			this.banner = this.initialData.banner
				? this.initialData.banner
				: null
			this.bannerId = this.initialData.banner
				? this.initialData.banner.id
				: null
		}

		// debounce registering
		this.debouncedAltTextChanged = _.debounce(this.altTextChanged, 500)
	},
	computed: {
		errors: {
			get() {
				return this.$store.state.professional.errors.awards
			},
		},
		haveErrors: {
			get() {
				return this.$store.state.professional.haveErrors
			},
		},
	},
	methods: {
		nameErrors() {
			const errors = []
			if (!this.$v.name.$dirty) return errors

			!this.$v.name.required && errors.push('Award Name is required')
			return errors
		},
		givenByErrors() {
			const errors = []
			if (!this.$v.givenBy.$dirty) return errors

			!this.$v.givenBy.minLength &&
				errors.push('Field should contain atleast 2 letters')
			return errors
		},

		altTextChanged() {
			let text = this.altText
			if (this.bannerId && this.altText !== '' && this.altText !== null) {
				this.axios({
					method: 'patch',
					url: `/media/${this.bannerId}`,
					data: { altText: text },
				})
					.then((res) => {
						if (res.status === 404) {
							console.log(404)
						}

						if (res.status == 200 && res.data.success) {
							// this.alert.message = res.data.message
						}
					})
					.catch(() => {
						// let errors = error.response.data
						// this.alert.message = errors.message
					})
			}
		},
		dropdown: function () {
			let currentYear = new Date().getFullYear()
			let yearArray = this.yearsArray
			let earliestYear = 1960
			while (currentYear >= earliestYear) {
				yearArray.push(currentYear)
				currentYear -= 1
			}
			this.yearsArray = yearArray
		},
		validateAwards() {
			let payload = {
				year: this.year,
				name: this.name,
				givenBy: this.givenBy,
				uuid: this.uuid,
				banner: this.banner,
			}
			this.altTextChanged()

			this.$store.dispatch('professional/validateAwardData', {
				awardData: payload,
				isEdit: this.isEdit,
			})
			if (!this.haveErrors) {
				this.resetValidation()
			}
		},
		deleteAward(id) {
			this.$store.commit('professional/deleteAward', id)
		},
		resetValidation() {
			if (this.haveErrors) {
				this.$store.commit('professional/clearErrors')
				this.$emit('closeModal')
			} else {
				this.$emit('closeModal')
			}
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				const data = val[0]
				this.bannerId = data.id
				this.purpose = 'banner'
				this.banner = {
					id: this.bannerId,
					publicURL: data.publicURL,
				}
			} else {
				this.bannerId = null
				this.banner = null
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
	},
}
</script>
