<template>
	<v-card class="pa-6">
		<div class="d-flex flex-row justify-space-between align-end mb-5">
			<h1 class="text-h6">Accomplishments ({{ awards.length }})</h1>
			<v-btn
				color="var(--blue)"
				dark
				right
				depressed
				rounded
				elevation="0"
				class="add-more py-0 px-4"
				@click="showDialog = !showDialog"
			>
				<v-icon left>mdi-plus</v-icon> Add
			</v-btn>
		</div>

		<Awards
			v-for="award in awards"
			:key="award.uuid"
			:award="award"
		></Awards>

		<AwardForm
			v-if="showDialog"
			:dialog="showDialog"
			@closeModal="closeModal()"
		></AwardForm>
	</v-card>
</template>

<script>
import Awards from './Awards'
import AwardForm from './AwardForm'
export default {
	components: {
		Awards,
		AwardForm,
	},
	data() {
		return {
			showDialog: false,
		}
	},
	computed: {
		awards: function () {
			return this.$store.state.professional.awards
		},
	},
	methods: {
		closeModal() {
			this.showDialog = !this.showDialog
		},
	},
}
</script>

<style scoped>
.add-more {
	color: white;
	font-size: 13px !important;
}
.add-more i {
	font-size: 18px !important;
}
</style>
