<template>
	<v-card outlined>
		<v-row class="ma-0 pa-6">
			<v-col cols="2" class="pa-0">
				<div class="img-wrapper">
					<div v-if="banner !== null">
						<v-img :src="banner.publicURL"></v-img>
					</div>
					<div v-else>
						<p>No Image Uploaded</p>
					</div>
				</div></v-col
			>
			<v-col cols="10" class="pl-5 pa-0 pt-1 d-flex">
				<div class="d-flex flex-column">
					<div class="d-flex flex-row">
						<!-- <img src="../../../assets/NoData.png" /> -->
						<h1 class="font-weight-regular heading">Full Name :</h1>
						<h3
							class="content font-weight-light pl-3"
							color="#5F615A"
						>
							{{ title !== 'None' ? title : '' }} {{ firstName }}
							{{ middleName }}
							{{ lastName }}
						</h3>
					</div>
					<div class="d-flex flex-row">
						<h1 class="font-weight-regular heading">
							Practice Start Year :
						</h1>
						<h3 class="content font-weight-light pl-3">
							{{ practiceStartYear }}
						</h3>
					</div>
					<div class="d-flex flex-row">
						<h1 class="font-weight-regular heading">Gender :</h1>
						<h3 class="content font-weight-light pl-3">
							{{ gender }}
						</h3>
					</div>
				</div>
				<v-spacer></v-spacer>
				<v-btn
					fab
					small
					color="white"
					elevation="0"
					right
					class="edit pa-0"
					@click="showDialog = !showDialog"
				>
					<v-icon> mdi-pencil </v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<PersonalDetailsForm
			v-if="showDialog"
			:dialog="showDialog"
			@closeModal="closeModal"
		></PersonalDetailsForm>
	</v-card>
</template>
<script>
import PersonalDetailsForm from './PersonalDetailsForm'

export default {
	components: {
		PersonalDetailsForm,
	},
	data() {
		return {
			showDialog: false,
		}
	},
	created() {
		this.$route.path.includes('/create')
			? (this.showDialog = true)
			: (this.showDialog = false)
	},
	computed: {
		title: {
			get() {
				return this.$store.state.professional.title
			},
		},
		firstName: {
			get() {
				return this.$store.state.professional.firstName
			},
		},
		middleName: {
			get() {
				return this.$store.state.professional.middleName
			},
		},
		lastName: {
			get() {
				return this.$store.state.professional.lastName
			},
		},
		gender: {
			get() {
				return this.$store.state.professional.gender
			},
		},
		practiceStartYear: {
			get() {
				return this.$store.state.professional.practiceStartYear
			},
		},
		banner: {
			get() {
				return this.$store.state.professional.banner
			},
		},
	},
	methods: {
		closeModal() {
			this.showDialog = !this.showDialog
		},
		dropzoneError(val) {
			console.log(val)
		},
	},
}
</script>
<style scoped>
.heading,
.content {
	font-size: 16px;
}
.heading {
	color: var(--blue);
}
a {
	text-decoration: none !important;
}
li:not(:first-child)::before {
	content: '\00B7';
	margin: 0;
	padding: 0 6px;
	text-decoration: none !important;
	color: var(--black, #000);
	font-weight: 400;
}
li {
	display: inline;
}
a:hover {
	text-decoration: underline !important;
}
.edit {
	color: var(--blue) !important;
}
.img-wrapper {
	overflow: hidden;
	border: 1px solid transparent;
	border-radius: 10px;
	max-height: 160px !important;
}
</style>
