<template>
	<v-dialog v-model="openDialog" persistent max-width="800px">
		<v-card class="pa-6 mb-0">
			<div class="d-flex justify-space-between align-start">
				<h1 class="text-h6 mb-5">Edit Academic Qualifications</h1>
				<span
					class="mdi mdi-close close"
					@click="resetValidation()"
				></span>
			</div>

			<v-form @submit.prevent="validateQualification" ref="form">
				<v-col cols="12" class="row-margin pa-0">
					<p>Course Name* :</p>
					<v-text-field
						class="pa-0"
						v-model="course"
						placeholder="Ex. MDS/MBBS"
						@input="$v.course.$touch()"
						@blur="$v.course.$touch()"
						:error-messages="courseErrors()"
					></v-text-field>
				</v-col>

				<v-col cols="12" class="row-margin pa-0">
					<p>College:</p>
					<v-text-field
						class="pa-0"
						v-model="institute"
						placeholder="Ex. Nair Hospital Dental College (NHDC), Mumbai"
						@input="$v.institute.$touch()"
						@blur="$v.institute.$touch()"
						:error-messages="instituteErros()"
					></v-text-field>
				</v-col>

				<v-row>
					<v-col cols="4" class="ma-0 pa-0 pb-2 pr-4">
						<p>Year of Graduation:</p>
						<v-select
							class="pa-0"
							:items="yearsArray"
							placeholder="Select Year"
							@focus="dropdown()"
							v-model="graduationYear"
							:error-messages="
								errors ? errors.graduationYear : ''
							"
						></v-select>
					</v-col>
					<v-col cols="8" class="ma-0 pa-0">
						<p>Field of Study:</p>
						<v-text-field
							class="pa-0"
							v-model="fieldOfStudy"
							placeholder="Ex. MDS/MBBS"
							@input="$v.fieldOfStudy.$touch()"
							@blur="$v.fieldOfStudy.$touch()"
							:error-messages="fieldOfStudyErros()"
						></v-text-field>
					</v-col>
				</v-row>

				<v-card-actions class="pt-6 card-actions">
					<v-btn
						v-if="isEdit"
						color="rgba(0,0,0,.6)"
						outlined
						class="delete-btn pa-3"
						@click="deleteQualification(uuid)"
					>
						Delete
					</v-btn>

					<v-spacer></v-spacer>

					<v-btn
						color="#2b6dc2"
						depressed
						type="submit"
						class="save-btn pa-3"
					>
						Done
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import { required, alpha } from 'vuelidate/lib/validators'

export default {
	props: {
		dialog: {
			type: Boolean,
		},
		initialData: {
			type: Object,
		},
		isEdit: {
			type: Boolean,
		},
	},
	data() {
		return {
			openDialog: false,
			yearsArray: [],
			graduationYear: null,
			course: '',
			institute: '',
			id: '',
			uuid: '',
			fieldOfStudy: '',
		}
	},
	validations: {
		course: {
			required,
		},
		institute: {
			alpha,
		},
		fieldOfStudy: {
			alpha,
		},
	},

	watch: {
		graduationYear() {
			if (this.errors) {
				this.errors.graduationYear = ''
			}
		},
		course() {
			if (this.errors) {
				this.errors.course = ''
			}
		},
		institute() {
			if (this.errors) {
				this.errors.institute = ''
			}
		},
	},
	created() {
		this.openDialog = this.dialog
		if (this.isEdit) {
			this.graduationYear = this.initialData.graduationYear
			this.course = this.initialData.course
			this.institute = this.initialData.institute
			this.uuid = this.initialData.uuid
			this.fieldOfStudy = this.initialData.fieldOfStudy
		}
	},
	computed: {
		errors: {
			get() {
				return this.$store.state.professional.errors.qualifications
			},
		},
		haveErrors: {
			get() {
				return this.$store.state.professional.haveErrors
			},
		},
	},
	methods: {
		courseErrors() {
			const errors = []
			if (!this.$v.course.$dirty) return errors

			!this.$v.course.required && errors.push('Course Name is required')
			return errors
		},
		instituteErros() {
			const errors = []
			if (!this.$v.institute.$dirty) return errors

			!this.$v.institute.alpha &&
				errors.push("Institute Name can't contain numbers")
			return errors
		},
		fieldOfStudyErros() {
			const errors = []
			if (!this.$v.fieldOfStudy.$dirty) return errors

			!this.$v.fieldOfStudy.alpha &&
				errors.push("Field of study can't contain numbers")
			return errors
		},
		dropdown: function () {
			let currentYear = new Date().getFullYear()
			let yearArray = this.yearsArray
			let earliestYear = 1960
			while (currentYear >= earliestYear) {
				yearArray.push(currentYear)
				currentYear -= 1
			}
			this.yearsArray = yearArray
		},
		validateQualification: function () {
			let payload = {
				graduationYear: this.graduationYear,
				course: this.course,
				institute: this.institute,
				uuid: this.uuid,
				fieldOfStudy: this.fieldOfStudy,
			}
			this.$store.dispatch('professional/validateQualificationData', {
				qualificationData: payload,
				isEdit: this.isEdit,
			})
			if (!this.haveErrors) {
				this.resetValidation()
			}
		},
		deleteQualification(id) {
			this.$store.commit('professional/deleteQualification', id)
		},
		resetValidation() {
			if (this.haveErrors) {
				this.$store.commit('professional/clearErrors')
				this.$emit('closeModal')
			} else {
				this.$emit('closeModal')
			}
		},
	},
}
</script>
