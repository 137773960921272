<template>
	<v-dialog v-model="openDialog" persistent max-width="800px">
		<v-card class="pa-6 mb-0">
			<div class="d-flex justify-space-between align-start">
				<h1 class="text-h6 mb-5">Edit Registration</h1>
				<span
					class="mdi mdi-close close"
					@click="resetValidation()"
				></span>
			</div>

			<v-form @submit.prevent="validateRegistration" ref="form">
				<v-row>
					<v-col cols="12" class="px-0 pb-0">
						<p>Registration Council Name* :</p>
						<v-text-field
							class="pa-0"
							v-model="councilName"
							placeholder="Medical Practitioner at Karnataka Medical Council"
							@input="$v.councilName.$touch()"
							@blur="$v.councilName.$touch()"
							:error-messages="councilNameErrors()"
						></v-text-field>
					</v-col>

					<v-col cols="3" class="ma-0 pa-0 pr-4">
						<p>Registration Year :</p>
						<v-select
							class="pa-0"
							:items="years"
							placeholder="Select Year"
							@focus="dropdown()"
							v-model="registrationYear"
							:error-messages="
								errors ? errors.registrationYear : ''
							"
						></v-select>
					</v-col>

					<v-col cols="9" class="py-0 pr-0">
						<p>Registration Number:</p>
						<v-text-field
							class="pa-0"
							v-model="registrationNumber"
							placeholder="53772"
							type="number"
							:error-messages="
								errors ? errors.registrationNumber : ''
							"
						></v-text-field>
					</v-col>
				</v-row>

				<v-card-actions class="pt-6 card-actions">
					<v-btn
						v-if="isEdit"
						color="rgba(0,0,0,.6)"
						outlined
						class="delete-btn pa-3"
						@click="deleteRegistration(uuid)"
					>
						Delete
					</v-btn>

					<v-spacer></v-spacer>

					<v-btn
						color="var(--blue)"
						depressed
						type="submit"
						class="save-btn pa-3"
					>
						Done
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
	props: {
		dialog: {
			type: Boolean,
		},
		initialData: {
			type: Object,
		},
		isEdit: {
			type: Boolean,
		},
	},
	data() {
		return {
			openDialog: false,
			years: [],
			registrationYear: '',
			councilName: '',
			registrationNumber: null,
		}
	},
	validations: {
		councilName: {
			required,
		},
	},
	created() {
		this.openDialog = this.dialog
		if (this.isEdit) {
			this.registrationYear = this.initialData.registrationYear
			this.councilName = this.initialData.councilName
			this.registrationNumber = this.initialData.registrationNumber
			this.initialData.id
				? (this.id = this.initialData.id)
				: (this.id = '')
			this.uuid = this.initialData.uuid
		}
	},
	computed: {
		errors: {
			get() {
				return this.$store.state.professional.errors.registrations
			},
		},
		haveErrors: {
			get() {
				return this.$store.state.professional.haveErrors
			},
		},
	},
	watch: {
		registrationYear() {
			if (this.errors) {
				this.errors.registrationYear = ''
			}
		},
		councilName() {
			if (this.errors) {
				this.errors.councilName = ''
			}
		},
		registrationNumber() {
			if (this.errors) {
				this.errors.registrationNumber = ''
			}
		},
	},
	methods: {
		councilNameErrors() {
			const errors = []
			if (!this.$v.councilName.$dirty) return errors

			!this.$v.councilName.required &&
				errors.push('Council Name is required')
			return errors
		},
		dropdown: function () {
			let currentYear = new Date().getFullYear()
			let yearArray = this.years
			let earliestYear = 1940
			while (currentYear >= earliestYear) {
				yearArray.push(currentYear)
				currentYear -= 1
			}
			this.years = yearArray
		},
		validateRegistration() {
			let payload = {
				registrationYear: this.registrationYear,
				councilName: this.councilName,
				registrationNumber: this.registrationNumber,
				id: this.id,
				uuid: this.uuid,
			}
			this.$store.dispatch('professional/validateRegistrationData', {
				registrationData: payload,
				isEdit: this.isEdit,
			})
			if (!this.haveErrors) {
				this.resetValidation()
			}
		},
		deleteRegistration(id) {
			this.$store.commit('professional/deleteRegistration', id)
		},
		resetValidation() {
			if (this.haveErrors) {
				this.$store.commit('professional/clearErrors')
				this.$emit('closeModal')
			} else {
				this.$emit('closeModal')
			}
		},
	},
}
</script>
