<template>
	<v-card class="pa-6">
		<h1 class="text-h6 mb-5">Contact Information</h1>
		<v-row>
			<v-col cols="12" class="row-margin pa-0">
				<p>Emails:</p>
				<v-text-field
					v-model="email"
					@keyup.enter="updateContact('email')"
					:error-messages="emailErrorMessage"
					placeholder="Enter your Emails here"
				></v-text-field>
				<div v-if="showChip">
					<v-chip
						v-for="email in emails"
						:key="email.uuid"
						class="ma-2"
						close
						@click:close="removeEmail(email.uuid)"
					>
						{{ email.email }}
					</v-chip>
				</div>
			</v-col>

			<v-col cols="12" class="row-margin pa-0">
				<p>Phones:</p>
				<v-text-field
					type="number"
					v-model="phone"
					@keyup.enter="updateContact('phone')"
					placeholder="Enter your Phones here"
				></v-text-field>
				<div v-if="showChip">
					<v-chip
						v-for="phone in phones"
						:key="phone.uuid"
						class="ma-2"
						close
						@click:close="removePhone(phone.uuid)"
					>
						{{ phone.phone }}
					</v-chip>
				</div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
	data() {
		return {
			email: '',
			showChip: false,
			phone: null,
			emailErrorMessage: '',
		}
	},
	watch: {
		email(value) {
			if (value) {
				if (/.+@.+\..+/.test(value)) {
					this.emailErrorMessage = ''
				} else {
					this.emailErrorMessage = 'Email must be valid'
				}
			}
		},
	},
	created() {
		if (this.$route.path.includes('/edit')) {
			this.showChip = true
		}
	},
	computed: {
		emails: {
			get() {
				return this.$store.state.professional.emails
			},
		},
		phones: {
			get() {
				return this.$store.state.professional.phones
			},
		},
	},
	methods: {
		updateContact(value) {
			let payload = {}
			if (value === 'email') {
				payload = {
					type: 'email',
					email: this.email,
					uuid: uuidv4(),
				}
			} else if (value === 'phone') {
				payload = {
					type: 'phone',
					phone: this.phone,
					uuid: uuidv4(),
				}
			}
			this.$store.commit('professional/updateContact', payload)
			this.showChip = true
			this.email = ''
			this.phone = null
		},
		removeEmail(id) {
			this.$store.commit('professional/deleteEmail', id)
		},
		removePhone(id) {
			this.$store.commit('professional/deletePhone', id)
		},
	},
}
</script>
