<template>
	<div
		class="
			mt-3
			mx-0
			body-2
			font-weight-light
			d-flex
			flex-row
			justify-space-between
			align-start
		"
		@mouseover="display = true"
		@mouseleave="display = false"
	>
		<div>
			<h4 class="entity-title font-weight-normal subtitle-1">
				{{ experience.role }}
			</h4>
			<p class="second-line">
				{{ experience.organisation }}
			</p>
			<p class="second-line">
				{{ experience.startedYear }} - {{ experience.endedYear }}
			</p>
		</div>
		<v-btn
			v-show="display"
			fab
			small
			color="white"
			dark
			elevation="0"
			right
			class="edit"
			absolute
			@click="showDialog = !showDialog"
		>
			<v-icon> mdi-pencil </v-icon>
		</v-btn>
		<ExperienceForm
			v-if="showDialog"
			:isEdit="true"
			:initialData="experience"
			:dialog="showDialog"
			@closeModal="closeModal"
		></ExperienceForm>
	</div>
</template>
<script>
import ExperienceForm from './ExperienceForm'
export default {
	components: { ExperienceForm },
	props: {
		experience: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			display: false,
			showDialog: false,
		}
	},
	methods: {
		closeModal() {
			this.showDialog = !this.showDialog
		},
	},
}
</script>
<style scoped>
.edit {
	background-color: white;
	color: var(--blue);
}
.edit:hover {
	background-color: var(--blue-bg) !important;
}
</style>
