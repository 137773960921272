import { render, staticRenderFns } from "./ListOfPublications.vue?vue&type=template&id=e3c048a4&scoped=true"
import script from "./ListOfPublications.vue?vue&type=script&lang=js"
export * from "./ListOfPublications.vue?vue&type=script&lang=js"
import style0 from "./ListOfPublications.vue?vue&type=style&index=0&id=e3c048a4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3c048a4",
  null
  
)

export default component.exports