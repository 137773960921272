<template>
	<v-dialog v-model="openDialog" persistent max-width="800px">
		<v-card class="pa-6 mb-0">
			<div class="d-flex justify-space-between align-start">
				<h1 class="text-h6 mb-5">Edit Experience</h1>
				<span
					class="mdi mdi-close close"
					@click="resetValidation()"
				></span>
			</div>

			<v-form @submit.prevent="validateExperience" ref="form">
				<v-row>
					<v-col cols="12" class="px-0 pb-0">
						<p>Role* :</p>
						<v-text-field
							class="pa-0"
							v-model="role"
							placeholder="Surveillance Medical Office"
							@input="$v.role.$touch()"
							@blur="$v.role.$touch()"
							:error-messages="roleErrors()"
						></v-text-field>
					</v-col>

					<v-col cols="12" class="px-0 pb-0">
						<p>Organisation:</p>
						<v-text-field
							class="pa-0"
							v-model="organisation"
							placeholder=" NPSP, World Health Organization & Government of India"
							:error-messages="errors ? errors.organisation : ''"
						></v-text-field>
					</v-col>

					<v-col cols="6" class="ma-0 pa-0 pr-4">
						<p>Start Year :</p>
						<v-select
							class="pa-0"
							:items="yearsArray"
							placeholder="Select Year"
							@focus="dropdown()"
							v-model="startedYear"
							:error-messages="errors ? errors.startedYear : ''"
						></v-select>
					</v-col>
					<v-col cols="6" class="py-0 pr-0">
						<p>End Year:</p>
						<v-select
							class="pa-0"
							:items="yearsArray"
							placeholder="Select Year"
							@focus="dropdown()"
							v-model="endedYear"
							:error-messages="errors ? errors.endedYear : ''"
						></v-select>
					</v-col>
				</v-row>

				<v-card-actions class="pt-6 card-actions">
					<v-btn
						v-if="isEdit"
						color="rgba(0,0,0,.6)"
						outlined
						class="delete-btn pa-3"
						@click="deleteExperience(uuid)"
					>
						Delete
					</v-btn>

					<v-spacer></v-spacer>

					<v-btn
						color="#2b6dc2"
						depressed
						type="submit"
						class="save-btn pa-3"
					>
						Done
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
	props: {
		dialog: {
			type: Boolean,
		},
		initialData: {
			type: Object,
		},
		isEdit: {
			type: Boolean,
		},
	},
	data() {
		return {
			openDialog: false,
			yearsArray: [],
			startedYear: null,
			endedYear: null,
			role: '',
			organisation: '',
		}
	},
	validations: {
		role: {
			required,
		},
	},
	watch: {
		startedYear() {
			if (this.errors) {
				this.errors.startedYear = ''
			}
		},
		endedYear() {
			if (this.errors) {
				this.errors.endedYear = ''
			}
		},
		role() {
			if (this.errors) {
				this.errors.role = ''
			}
		},
		organisation() {
			if (this.errors) {
				this.errors.organisation = ''
			}
		},
	},
	created() {
		this.openDialog = this.dialog
		if (this.isEdit) {
			this.startedYear = this.initialData.startedYear
			this.endedYear = this.initialData.endedYear
			this.role = this.initialData.role
			this.organisation = this.initialData.organisation
			this.initialData.id
				? (this.id = this.initialData.id)
				: (this.id = '')
			this.uuid = this.initialData.uuid
		}
	},
	computed: {
		errors: {
			get() {
				return this.$store.state.professional.errors.experiences
			},
		},
		haveErrors: {
			get() {
				return this.$store.state.professional.haveErrors
			},
		},
	},
	methods: {
		roleErrors() {
			const errors = []
			if (!this.$v.role.$dirty) return errors

			!this.$v.role.required && errors.push('Role is required')
			return errors
		},
		dropdown: function () {
			let currentYear = new Date().getFullYear()
			let yearArray = this.yearsArray
			let earliestYear = 1940
			while (currentYear >= earliestYear) {
				yearArray.push(currentYear)
				currentYear -= 1
			}
			this.yearsArray = yearArray
		},
		validateExperience() {
			let payload = {
				startedYear: this.startedYear,
				endedYear: this.endedYear,
				role: this.role,
				organisation: this.organisation,
				id: this.id,
				uuid: this.uuid,
			}
			this.$store.dispatch('professional/validateExperienceData', {
				experienceData: payload,
				isEdit: this.isEdit,
			})
			if (!this.haveErrors) {
				this.resetValidation()
			}
		},
		deleteExperience(id) {
			this.$store.commit('professional/deleteExperience', id)
		},
		resetValidation() {
			if (this.haveErrors) {
				this.$store.commit('professional/clearErrors')
				this.$emit('closeModal')
			} else {
				this.$emit('closeModal')
			}
		},
	},
}
</script>
