<template>
	<v-card class="pa-6">
		<div class="d-flex flex-column justify-space-between align-start mb-5">
			<h1 class="text-h6 mb-3">About</h1>
			<div id="ckcontent">
				<Editor
					:initData="bio"
					:purpose="`About for the professionals`"
					@changed="editorChanged"
				></Editor>
				<span
					class="error-msg"
					v-for="(error, ind) in bioErrors()"
					:key="ind"
				>
					{{ error }}
				</span>
			</div>
		</div>
	</v-card>
</template>

<script>
import Editor from '@/components/CkEditor'
import { minLength } from 'vuelidate/lib/validators'

export default {
	components: {
		Editor,
	},
	validations: {
		bio: {
			minLength: minLength(17),
		},
	},
	computed: {
		bio: {
			get() {
				return this.$store.state.professional.bio
			},
		},
	},
	methods: {
		bioErrors() {
			const errors = []
			if (!this.$v.bio.$dirty) return errors

			!this.$v.bio.minLength &&
				errors.push('About must be atleast 10 letters long')
			return errors
		},
		commitBio(value) {
			this.$store.commit('professional/updateBio', value)
		},
		editorChanged(body) {
			this.$v.bio.$touch()
			this.commitBio(body)
		},
	},
}
</script>

<style scoped>
#ckcontent {
	width: 100%;
}
</style>
