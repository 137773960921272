<template>
	<v-card class="pa-6">
		<div class="d-flex flex-row justify-space-between align-end mb-5">
			<h1 class="text-h6">
				Academic Qualification ({{ qualifications.length }})
			</h1>
			<v-btn
				color="var(--blue)"
				dark
				right
				depressed
				rounded
				elevation="0"
				class="add-more py-0 px-4"
				@click="showDialog = !showDialog"
			>
				<v-icon left>mdi-plus</v-icon> Add
			</v-btn>
		</div>

		<Courses
			v-for="qualification in qualifications"
			:key="qualification.uuid"
			:qualification="qualification"
		></Courses>

		<CourseForm
			v-if="showDialog"
			:dialog="showDialog"
			@closeModal="closeModal"
		></CourseForm>
	</v-card>
</template>

<script>
import Courses from './Courses'
import CourseForm from './CourseForm'
export default {
	components: {
		Courses,
		CourseForm,
	},
	data() {
		return {
			showDialog: false,
		}
	},
	computed: {
		qualifications: {
			get() {
				return this.$store.state.professional.qualifications
			},
		},
	},
	methods: {
		closeModal() {
			this.showDialog = !this.showDialog
		},
	},
}
</script>

<style scoped>
.add-more {
	color: white;
	font-size: 13px !important;
}
.add-more i {
	font-size: 18px !important;
}
</style>
