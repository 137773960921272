<template>
	<v-card class="pa-6">
		<div class="d-flex flex-row justify-space-between align-end mb-5">
			<h1 class="text-h6">Registrations ({{ registrations.length }})</h1>
			<v-btn
				color="var(--blue)"
				dark
				right
				rounded
				elevation="0"
				class="add-more"
				@click="showDialog = !showDialog"
			>
				<v-icon left>mdi-plus</v-icon> Add
			</v-btn>
		</div>

		<ListOfRegistrations
			v-for="registration in registrations"
			:key="registration.uuid"
			:registration="registration"
		></ListOfRegistrations>

		<RegistrationForm
			v-if="showDialog"
			:dialog="showDialog"
			@closeModal="closeModal"
		></RegistrationForm>
	</v-card>
</template>

<script>
import ListOfRegistrations from './ListOfRegistrations'
import RegistrationForm from './RegistrationForm'
export default {
	components: {
		ListOfRegistrations,
		RegistrationForm,
	},
	data() {
		return {
			showDialog: false,
		}
	},
	computed: {
		registrations: {
			get() {
				return this.$store.state.professional.registrations
			},
		},
	},
	methods: {
		closeModal() {
			this.showDialog = !this.showDialog
		},
	},
}
</script>

<style scoped>
.add-more {
	color: white;
	font-size: 13px !important;
}
.add-more i {
	font-size: 18px !important;
}
</style>
