<template>
	<v-container
		v-if="$can('create', 'Professional') || $can('update', 'Professional')"
	>
		<div v-if="!isLoading">
			<DetailsCard></DetailsCard>

			<ContactInfomation></ContactInfomation>

			<About> </About>

			<ServicesAndMemberships></ServicesAndMemberships>

			<QualificationCard id="qualifications"></QualificationCard>

			<ExperienceCard id="experience"></ExperienceCard>

			<AwardCard id="awards"></AwardCard>

			<PublicationCard id="publications"></PublicationCard>

			<RegistartionCard id="registrations"></RegistartionCard>

			<v-row class="card-actions">
				<UnoSaveButton
					@click.native="saveData()"
					@primary-button-click="professionalListing()"
					primary-button-text="Move To Professional Listing"
					secondary-button-text="Continue Editing"
					:alert="alert"
					:saving="isSaving"
				></UnoSaveButton>

				<UnoPublishButton
					v-if="isEdit && $can('publish', 'Professional')"
					:isPublished="isPublished"
					@click.native="toggleStatus()"
				></UnoPublishButton>

				<UnoDeleteButton
					v-if="isEdit && $can('delete', 'Professional')"
					@confirm="deleteProfessional()"
				></UnoDeleteButton>
			</v-row>
		</div>
	</v-container>
</template>

<script>
import DetailsCard from './components/DetailsCard'
import About from './components/About'

import ServicesAndMemberships from './components/ServicesAndMemberships'
import QualificationCard from './components/QualificationCard'
import AwardCard from './components/AwardCard'
import PublicationCard from './components/PublicationCard'
import RegistartionCard from './components/RegistartionCard'
import ExperienceCard from './components/ExperienceCard'
import ContactInfomation from './components/ContactInfomation'
// ACTION BUTTONS
import UnoPublishButton from '@/components/Buttons/UnoPublishButton'
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'

export default {
	data() {
		return {
			isSaving: false,
			deleteConfirm: false,
			showDelete: false,
		}
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
			required: true,
		},
		id: {
			type: String,
			default: null,
		},
	},
	components: {
		RegistartionCard,
		ExperienceCard,
		DetailsCard,
		About,
		ServicesAndMemberships,
		QualificationCard,
		AwardCard,
		PublicationCard,
		ContactInfomation,
		UnoSaveButton,
		UnoDeleteButton,
		UnoPublishButton,
	},
	computed: {
		isPublished: {
			get() {
				return this.$store.state.professional.isPublished
			},
		},
		errors: {
			get() {
				return this.$store.state.professional.errors
			},
		},
		isLoading: {
			get() {
				return this.$store.state.professional.isLoading
			},
		},
		alert: {
			get() {
				return this.$store.state.professional.alert
			},
		},
	},
	created() {
		this.$store.commit('professional/setIsEdit', this.isEdit)

		if (this.isEdit) {
			this.$store.dispatch('professional/getInitialData', {
				id: this.id,
			})
		} else {
			this.$store.commit('professional/setIsLoading', false)
		}
	},
	methods: {
		async toggleStatus() {
			await this.$store.dispatch('professional/toggleStatus', {
				id: this.id,
			})
			// await this.$store.dispatch('testimony/getListingData', {
			// 	root: true,
			// })
		},
		async saveData() {
			this.isSaving = true
			await this.$store.dispatch('professional/saveProfessional')
			this.isSaving = false
		},
		deleteProfessional() {
			this.$store.dispatch('professional/deleteProfessional', {
				professionalId: this.$store.state.professional.id,
			})
			this.$router.push({
				name: 'professional.list',
			})
		},
		professionalListing() {
			this.$store.commit('professional/clearState')
			this.$router.push({
				name: 'professional.list',
			})
		},
	},
}
</script>
