<template>
	<v-dialog v-model="openDialog" persistent max-width="800px">
		<v-card class="pa-6 mb-0">
			<div class="d-flex justify-space-between align-start">
				<h1 class="text-h6 mb-5">Edit Publication</h1>
				<span
					class="mdi mdi-close close"
					@click="resetValidation()"
				></span>
			</div>

			<v-form @submit.prevent="validatePublication" ref="form">
				<v-row>
					<v-col cols="12" class="pa-0">
						<FileUploader
							:initFiles="banner ? [banner] : []"
							@errorOccured="dropzoneError"
							:purpose="`publications logo`"
							:maxFiles="1"
							title="Drag/Drop or click here to upload image"
							@changed="fileUploaderChanged"
						/>
					</v-col>

					<v-col cols="12" class="py-0 pr-0 pl-0">
						<p>Image Alt Text:</p>
						<v-text-field
							class="pa-0"
							v-model="altText"
							placeholder="Enter Alt Text"
							:error-messages="errors ? errors.altText : ''"
						></v-text-field>
					</v-col>

					<v-col cols="12" class="pa-0">
						<p>Title* :</p>
						<v-text-field
							class="pa-0"
							v-model="name"
							placeholder="Best Oncologist of India"
							@input="$v.name.$touch()"
							@blur="$v.name.$touch()"
							:error-messages="nameErrors()"
						></v-text-field>
					</v-col>

					<v-col cols="9" class="pr-4 pa-0">
						<p>Journal :</p>
						<v-text-field
							class="pa-0"
							v-model="journal"
							placeholder="New York Times"
							:error-messages="errors ? errors.journal : ''"
						></v-text-field>
					</v-col>
					<v-col cols="3" class="ma-0 pa-0">
						<p>Published Year :</p>
						<v-select
							class="pa-0"
							:items="years"
							placeholder="Select Year"
							@focus="dropdown()"
							v-model="publishedYear"
							:error-messages="errors ? errors.publishedYear : ''"
						></v-select>
					</v-col>

					<v-col cols="12" class="pa-0">
						<p>Link :</p>
						<v-text-field
							class="pa-0"
							v-model="url"
							placeholder="Ex - https://link.springer.com/article/..."
							@input="$v.url.$touch()"
							@blur="$v.url.$touch()"
							:error-messages="urlErrors()"
						></v-text-field>
					</v-col>

					<v-col cols="12" class="pa-0">
						<p class="mb-3">Description* :</p>
						<v-textarea
							class="pa-0"
							v-model="description"
							name="description"
							placeholder="Enter Description"
							outlined
							no-resize
							auto-grow
							@input="$v.description.$touch()"
							@blur="$v.description.$touch()"
							:error-messages="descriptionErrors()"
						></v-textarea>
					</v-col>
				</v-row>

				<v-card-actions class="pt-6 card-actions">
					<v-btn
						v-if="isEdit"
						color="rgba(0,0,0,.6)"
						outlined
						class="delete-btn pa-3"
						@click="deletePublication(uuid)"
					>
						Delete
					</v-btn>

					<v-spacer></v-spacer>

					<v-btn
						color="#2b6dc2"
						depressed
						type="submit"
						class="save-btn pa-3"
					>
						Done
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import FileUploader from '@/components/FileUploader'
import _ from 'lodash'
import { required, url } from 'vuelidate/lib/validators'

export default {
	components: {
		FileUploader,
	},
	props: {
		dialog: {
			type: Boolean,
		},
		initialData: {
			type: Object,
		},
		isEdit: {
			type: Boolean,
		},
	},
	data() {
		return {
			openDialog: false,
			years: [],
			publishedYear: null,
			name: '',
			journal: '',
			url: null,
			altText: '',
			description: '',
			banner: null,
			bannerId: null,
		}
	},
	validations: {
		name: {
			required,
		},
		description: {
			required,
		},
		url: {
			url,
		},
	},
	watch: {
		publishedYear() {
			if (this.errors) {
				this.errors.publishedYear = ''
			}
		},
		name() {
			if (this.errors) {
				this.errors.name = ''
			}
		},
		altText() {
			this.altTextChanged()
		},
		journal() {
			if (this.errors) {
				this.errors.journal = ''
			}
		},
	},
	created() {
		this.openDialog = this.dialog
		if (this.isEdit) {
			this.publishedYear = this.initialData.publishedYear
			this.journal = this.initialData.journal
			this.name = this.initialData.name
			this.initialData.id
				? (this.id = this.initialData.id)
				: (this.id = '')
			this.uuid = this.initialData.uuid
			this.url = this.initialData.url
			this.description = this.initialData.description
			this.altText =
				this.initialData.banner && this.initialData.banner.altText
					? this.initialData.banner.altText
					: null
			this.banner = this.initialData.banner
				? this.initialData.banner
				: null
			this.bannerId = this.initialData.banner
				? this.initialData.banner.id
				: null
		}

		// debounce registering
		this.debouncedAltTextChanged = _.debounce(this.altTextChanged, 500)
	},
	computed: {
		errors: {
			get() {
				return this.$store.state.professional.errors.publications
			},
		},
		haveErrors: {
			get() {
				return this.$store.state.professional.haveErrors
			},
		},
	},
	methods: {
		nameErrors() {
			const errors = []
			if (!this.$v.name.$dirty) return errors

			!this.$v.name.required && errors.push('Title is required')
			return errors
		},
		descriptionErrors() {
			const errors = []
			if (!this.$v.description.$dirty) return errors

			!this.$v.description.required &&
				errors.push('Description is required')
			return errors
		},
		urlErrors() {
			const errors = []
			if (!this.$v.url.$dirty) return errors

			!this.$v.url.url && errors.push('Enter a valid url')
			return errors
		},
		altTextChanged() {
			let text = this.altText
			if ((this.bannerId && this.altText !== '') || null) {
				this.axios({
					method: 'patch',
					url: `/media/${this.bannerId}`,
					data: { altText: text },
				})
					.then((res) => {
						if (res.status === 404) {
							console.log(404)
						}

						if (res.status == 200 && res.data.success) {
							// this.alert.message = res.data.message
						}
					})
					.catch(() => {
						// let errors = error.response.data
						// this.alert.message = errors.message
					})
			}
		},
		dropdown: function () {
			let currentYear = new Date().getFullYear()
			let yearArray = this.years
			let earliestYear = 1940
			while (currentYear >= earliestYear) {
				yearArray.push(currentYear)
				currentYear -= 1
			}
			this.years = yearArray
		},
		validatePublication() {
			let payload = {
				publishedYear: this.publishedYear,
				name: this.name,
				journal: this.journal,
				url: this.url ? this.url : null,
				description: this.description,
				id: this.id,
				uuid: this.uuid,
				banner: this.banner,
			}
			this.altTextChanged()

			this.$store.dispatch('professional/validatePublicationData', {
				publicationData: payload,
				isEdit: this.isEdit,
			})
			if (!this.haveErrors) {
				this.resetValidation()
			}
		},
		deletePublication(id) {
			this.$store.commit('professional/deletePublication', id)
		},
		resetValidation() {
			if (this.haveErrors) {
				this.$store.commit('professional/clearErrors')
				this.$emit('closeModal')
			} else {
				this.$emit('closeModal')
			}
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				const data = val[0]
				this.bannerId = data.id
				this.purpose = 'banner'
				this.banner = {
					id: this.bannerId,
					publicURL: data.publicURL,
				}
			} else {
				this.bannerId = null
				this.banner = null
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
	},
}
</script>
